import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface multipleSelectProperties {
    componentName : string,
    items: string[],
    itemsList: string[],
    allItems: string [],
    allItemsSelected: boolean,
    perStore: boolean,
    disabled: boolean,
    submitted: boolean,
    reloadItemsList: boolean
}

const initialState: Array<multipleSelectProperties> = [
    {
        componentName : 'init',
        items: [],
        itemsList:[],
        allItems:[],
        allItemsSelected: false,
        perStore: false,
        disabled: false,
        submitted: false,
        reloadItemsList: false
    }
]

export const DropdownGenericSlice = () => createSlice({
    //name:'selectedStores',
    name: 'multipleSelect ',
    initialState: initialState,
    reducers:{
        addMultipleSelect: (state, { payload }: PayloadAction<{ componentName: string}>) => {
            const { componentName} = payload;
              state.push({
                componentName : componentName,
                items: [],
                itemsList:[],
                allItems:[],
                allItemsSelected: false,
                perStore: false,
                disabled: false,
                submitted: false,
                reloadItemsList: false})
            },

        setItems :(state, { payload }: PayloadAction<{ componentName: string, Items: string[] }>) => {
            const { componentName, Items } = payload;
            state[state.findIndex(el => el.componentName === componentName)].items = Items;
            },
        setItemsList :(state, { payload }: PayloadAction<{ componentName: string, ItemsList: string[] }>) => {
            const { componentName, ItemsList } = payload;
            state[state.findIndex(el => el.componentName === componentName)].itemsList = ItemsList;
            },
        setAllSelected:(state, { payload }: PayloadAction<{ componentName: string, allItemsSelected: boolean }>) => {
            const { componentName, allItemsSelected } = payload;
            state[state.findIndex(el => el.componentName === componentName)].allItemsSelected = allItemsSelected;
            },
        setAllItems:(state, { payload }: PayloadAction<{ componentName: string, allItems: string[] }>) => {
            const {componentName, allItems} = payload;
            state[state.findIndex(el => el.componentName === componentName)].allItems = allItems;
            },
        disable:(state, { payload }: PayloadAction<{ componentName: string, disabled: boolean }>) => {
            const { componentName, disabled } = payload;
            state[state.findIndex(el => el.componentName === componentName)].disabled = disabled;
            },
        reset :(state, { payload }: PayloadAction<{componentName: string }>) => {
            state[state.findIndex(el => el.componentName === payload.componentName)].items= [];
            },
        resetItemsList :(state, { payload }: PayloadAction<{componentName: string}>) => {
            state[state.findIndex(el => el.componentName === payload.componentName)].itemsList= [];
            },
        reloadItemsList:(state, { payload }: PayloadAction<{ componentName: string, reloadItemsList: boolean }>) => {
            const { componentName, reloadItemsList} = payload;
            state[state.findIndex(el => el.componentName === componentName)].reloadItemsList= reloadItemsList;
            },
        setPerStore :(state, { payload }: PayloadAction<{ componentName: string, perStore: boolean }>) => {
            const { componentName, perStore} = payload;
            state[state.findIndex(el => el.componentName === componentName)].perStore= perStore;
                    }
    }
})
{/*

*/}
//export default storesDropdownSlice.reducer

export default DropdownGenericSlice


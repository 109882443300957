import DropdownGenericSlice from "./dropdownGeneric";

const MultipleSelectSlice = DropdownGenericSlice();

export const {
    addMultipleSelect,setItems,setItemsList,setAllSelected,
    setAllItems,disable,reset,resetItemsList,
    reloadItemsList,setPerStore } = MultipleSelectSlice.actions

export default MultipleSelectSlice.reducer;

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user'
import salesPromiseReducer from './salesPromise';
import selectBoxReducer from './selectBox.js';
import dropDownReducer from './dropDown';
import budgetRateReducer from './budgetRate';
import modalEnableReducer from './modal';
import multiSelectReducer from './multiSelect';
import skusPostReducer from './skuEdit';
import dialogReducer from './dialog';
import salesReducer from './searchOptions';
import salesLoadingReducer from './loading';
import productParamsReducer from './productParams';
import loadersReducer from './loaders';
import multipleSelectSlice from './dropDownStores';

import suspiciousRowReducer from './suspiciousRow'

import {useSelector,TypedUseSelectorHook} from 'react-redux'

export const store = configureStore({
  reducer: {
    user: userReducer,
    sales: salesPromiseReducer,
    selectBox: selectBoxReducer,
    dropDown: dropDownReducer,
    budgetRates:budgetRateReducer,
    modalEnable:modalEnableReducer,
    multiSelect:multiSelectReducer,
    editSkus: skusPostReducer,
    dialogEnable: dialogReducer,
    salesParams: salesReducer,
    salesIsLoading: salesLoadingReducer,
    stores: multipleSelectSlice,
    productSales: productParamsReducer ,
    loaders: loadersReducer,
    suspiciousRow: suspiciousRowReducer
  },
});

export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook< RootState> = useSelector;
export type AppDispatch = typeof store.dispatch

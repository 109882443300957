import React from "react";
import { useEffect } from "react";
import {Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Box,
  Container}
  from '@material-ui/core'

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Input from '@material-ui/core/Input';
import { useDispatch, useSelector } from 'react-redux';
import { setItems,setAllSelected, reset, setPerStore, addMultipleSelect} from 'redux/dropDownStores';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300
    },
  },
};

export default function StoresDropDown({componentName,componentType, width}) {
const dispatcher = useDispatch()
const allComponents = useSelector(state => state.stores)
const component = allComponents.findIndex(el => el.componentName === componentName) == -1 ? 0:
                                                allComponents.findIndex(el => el.componentName === componentName)



const handleChange = (event) => {
  const value = event.target.value;
  if (value[value.length - 1] === "all") {
    dispatcher(setItems({ componentName:allComponents[component].componentName ,
      Items:allComponents[component].items.length === allComponents[component].itemsList.length ? [] : allComponents[component].itemsList}))
    dispatcher(setAllSelected({componentName:allComponents[component].componentName ,
       allItemsSelected: allComponents[component].items.length === allComponents[component].itemsList.length ? false : true}))
    return;
  }
  else{
    dispatcher(setItems({componentName:allComponents[component].componentName, Items :value}))
  }

};

 return (
  <Box sx ={{ml:8}}>
       <FormControl >
              <InputLabel id="stores-mutiple-checkbox-label">{componentType}</InputLabel>
                <Select
                  labelId="stores-mutiple-checkbox-label"
                  id="stores-mutiple-checkbox"
                 sx ={{
                  marginTop: 8,
                  marginBottom: 4,
                  width:{width}
                 }}
                  multiple
                  value={allComponents[component].items}
                  disabled = {allComponents[component].disabled}
                  onChange={handleChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  { allComponents[component].itemsList.length >0 &&
                  <MenuItem
                      value="all"
                  >
                      <ListItemIcon>
                        <Checkbox
                          checked={allComponents[component].allItemsSelected}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary="Select All"
                      />
                    </MenuItem> }
                    {allComponents[component].itemsList.map((item) => (
                    <MenuItem key={item} value={item}>
                        <Checkbox checked={allComponents[component].items.indexOf(item) > -1} />
                        <ListItemText primary={item} />
                    </MenuItem>
                    ))}
                </Select>
                </FormControl>
</Box>
 )
}




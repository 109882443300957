import { createSlice } from "@reduxjs/toolkit";
const chart = {'amount' :{'labels':[],'datasets':[]},
'quantity' :{'labels':[],'datasets':[]},
'total_sales':[{'sap_id':'',
 'store_name':'',
'store_number':'',
'item_price':0,
'quantity':0}]
}

const initialState = {
    products:[],
    chartData : {'amount' :{'labels':[],'datasets':[]},
                  'quantity' :{'labels':[],'datasets':[]},
                  'total_sales':[{'sap_id':'',
                   'store_name':'',
                  'store_number':'',
                  'item_price':0,
                  'quantity':0}]
                },
    disabled: true,
    submitted: false,
    districtEnabled: false,
    provinceEnabled: false
}

export const productParamsSlice = createSlice({
    name:'productSales',
    initialState: initialState,
    reducers:{
        selectedProducts : (state,action) => {
            state.products = action.payload
        },
        districtEnable : (state,action) => {
            state.districtEnabled = action.payload
        },
        provinceEnable: (state,action) => {
            state.provinceEnabled = action.payload
        },
        disable: (state, action) =>{
            state.disabled = action.payload
        },
        setSubmitted : (state,action) => {
            state.submitted = action.payload
        },
        setChartData:(state,action) =>{
            state.chartData = action.payload
        },
        resetChartData:(state) =>{
            state.chartData = chart
        }
    }
})
export const {
              setSubmitted,disable,
              setChartData,
              resetChartData,
              selectedProducts,
              districtEnable,
              provinceEnable
             } = productParamsSlice.actions

export default productParamsSlice.reducer
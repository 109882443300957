import React from "react";
import { useSelector } from "react-redux";

import SelectBox from "components/SelectBox";
import CollapsibleTable from "../table/CollapsibleTable"

import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import StoresDropDown from "components/General/StoresDropDown";
import { useEffect, useRef } from "react";
import {
  Button ,
  Box,
  Grid,
  TextField
  } from '@material-ui/core';

import {disable, setAllItems, addMultipleSelect} from 'redux/dropDownStores';
import {setItemsList} from 'redux/dropDownStores';
import {setRows } from 'redux/suspiciousRow';
import { useDispatch} from 'react-redux';
import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from "react-promise-tracker";
import {Bars} from 'react-loader-spinner';
import Backdrop from '@material-ui/core/Backdrop';
import qs from 'qs';

function VoidTransactions() {
  const [startDate,setStartDate] = React.useState(new Date())
  const [endDate,setEndDate] = React.useState(new Date())
  const [submitEndDate,setSubmitEndDate] = React.useState(new Date())
  const [submitStartDate,setSubmitStartDate] = React.useState(new Date())

  const [submitStatus,setSubmitStatus] = React.useState([])
  const [submitDisable,setSubmitDisable] = React.useState(true)
  const [allStores,setAllStores] = React.useState(false)
  const [fetchNeeded,setFetchNeeded] = React.useState(false)

  const selectedStatus = useSelector((state) => state.selectBox.selectedValue)

  const updateRow = useSelector(state => state.suspiciousRow)
  const [result, setResult] = React.useState([])
  const dispatcher = useDispatch();

  const allMultipleSelects = useSelector(state => state.stores)

  const [storesData,setStoresData] = React.useState(allMultipleSelects)
  const component = allMultipleSelects.findIndex(el => el.componentName === 'void') == -1 ? 0:
                                                 allMultipleSelects.findIndex(el => el.componentName === 'void')

  useEffect(() => {
    dispatcher(addMultipleSelect({componentName:'void'}))
     }, []);



    const IfMounted = () => {
      const isMountRef = useRef(true);
      useEffect(() => {
        isMountRef.current = false;
      }, []);
      return isMountRef.current;
    };
    const didMounted = IfMounted()

    useEffect(() => {
      axios.get('/api/storesDetail',
      {
        params: {
        'storeNumber':true
         }
        })
        .then(res => {
            dispatcher(setAllItems({componentName:'void',allItems:res.data.nameNumber}))
            dispatcher(setItemsList({componentName:'void' , ItemsList: res.data.nameNumber}))
        });
    },[]);

    useEffect(() => {
      if (allMultipleSelects[component].items.length == 0) {
         setSubmitDisable(true)
      }
      else{
        setSubmitDisable(false)
      }
    },[allMultipleSelects[component].items]);

    const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    if(!didMounted & fetchNeeded){
    trackPromise(
    axios.get("/api/purchase/void_transactions",
    {
      params: {
        statusRequest:submitStatus.selectedValue,
        stores: allMultipleSelects[component].items,
        startDate: submitStartDate,
        allStores: allStores,
        endDate: submitEndDate
      },
      paramsSerializer: params => {
        return qs.stringify(params,{ arrayFormat: 'repeat' })
      }
    })
    .then(res => {
      dispatcher(setRows({id:1, rows: res.data }))
  //    setResult(res.data);
      setFetchNeeded(false)
    })
  .catch((error) => console.log(error)))}
    },[submitStartDate, submitEndDate, submitStatus, allStores, fetchNeeded]);

    useEffect(() => {
      if(!didMounted){
      axios.post("/api/purchase/update_suspicious_transactions/",
        {
          row:updateRow[1].currentRow,
          status:updateRow[1].status,
          comment: updateRow[1].comment
         }
    )
      .then(()=>{
          setFetchNeeded(true)
              })
  .catch((error) => console.log(error))}
      },[updateRow[1].currentRow])

  const handleStartDateChange =(date) =>{
    setStartDate(date)
    }

   const handleEndDateChange =(date) =>{
      setEndDate(date)
      }
  console.log(selectedStatus)

  const handleSubmit =(event) =>{
    event.preventDefault();
    setSubmitStartDate(startDate)
    setSubmitEndDate(endDate)
    setSubmitStatus(selectedStatus)
    setStoresData(allMultipleSelects[component])
    setAllStores(allMultipleSelects[component].allItemsSelected)
    setFetchNeeded(true)
    }

  const options = ['Suspicious','Cleared']
  const defaultStatus = ['Suspicious','Cleared']

  return (
    <>
    <form  noValidate onSubmit={handleSubmit}>
    <Box pt={5} pb={5} pl={2}>
    <Grid
    container
    spacing={7}
    >
        <Grid
            item
            md={2}
            xs={2}
        >
           {
      promiseInProgress &&
      <Backdrop style={{zIndex: 2}} open={true} >
      <Bars type="Watch" color='#862D23' height="100" width="100" />
      </Backdrop>
      }
          <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker  variant="inline" inputVariant="outlined"
          label = "Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          renderInput={(params) => <TextField {...params} />}
          >
          </DatePicker>

        </LocalizationProvider>
        </Grid>
            <Grid
              item
              md={2}
              xs={2}
            >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker  variant="inline" inputVariant="outlined"
          label = "End Date"
          value={endDate}
          onChange={handleEndDateChange}
          renderInput={(params) => <TextField {...params} />}
          >
          </DatePicker>

        </LocalizationProvider>

              </Grid>
              <Grid
                item
                md={2}
                xs={2}
              >
                <SelectBox options ={options}  defaultStatus={defaultStatus}/>

          </Grid>
          <Grid item
                md={3}
                xs={3}
                style={{ marginTop: 8}}
          >
          <StoresDropDown componentName={'void'} componentType={'stores'} width={300}/>
          </Grid>
              <Grid item style={{ marginTop: 22}}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled = {submitDisable}
                >
                  Submit
                </Button>
            </Grid>
            </Grid>
              </Box>

            </form>

             <Grid
            item
            md={12}
            xs={12}
             >
            <CollapsibleTable id = {1} />

      </Grid>
    </>
  );
}

export default VoidTransactions;
